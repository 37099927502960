















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import utility from '@/common/utility'

@Component({
  components: {
    draggable
  }
})
export default class ColumnSelector extends Vue {
  name = 'WatchSymbols'
  selectedSourceKey: string = null
  selectedTargetKey: string = null
  @Prop() sourceKeys: string[]
  @Prop() targetKeys: string[]
  @Prop() visible: boolean
  innerVisible = false
  selectedKeys: string[] = []

  mounted () {
    if (this.targetKeys) {
      for (const key of this.targetKeys) {
        utility.removeArrayItem(this.sourceKeys, key)
      }
    }
    this.selectedKeys = utility.copyArray(this.targetKeys)
    this.innerVisible = this.visible
  }

  @Watch('visible')
  onValueChanged (v: boolean) {
    this.innerVisible = v
  }

  @Watch('innerVisible')
  onInputValueChanged (v: boolean) {
    this.$emit('update:visible', v)
  }

  @Emit('keysChanged')
  async moveToTarget () {
    if (this.selectedSourceKey && this.targetKeys) {
      utility.removeArrayItem(this.sourceKeys, this.selectedSourceKey)
      this.targetKeys.push(this.selectedSourceKey)
      this.selectedSourceKey = null
    }
    this.selectedKeys = utility.copyArray(this.targetKeys)
    return this.targetKeys
  }

  @Emit('keysChanged')
  async moveToSource () {
    if (this.selectedTargetKey && this.targetKeys) {
      if (this.targetKeys.length === 1) {
        await this.$alert('One column is required at least')
        return []
      }
      utility.removeArrayItem(this.targetKeys, this.selectedTargetKey)
      this.sourceKeys.push(this.selectedTargetKey)
      this.selectedTargetKey = null
    }
    this.selectedKeys = utility.copyArray(this.targetKeys)
    return this.targetKeys
  }

  @Emit('keysChanged')
  async dragEnd () {
    return this.selectedKeys
  }
}
